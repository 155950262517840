import React from 'react';
import { FormattedMessage } from '@kyruus/intl';

import {
  FacetFieldSet,
  StyledLegend,
  FacetWrapper,
  BasicFacetBody
} from '../search-v9/styles';

/**
 * Renders a sort section component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.searchSummary - The search summary.
 * @param {Function} props.getUpdatedSearch - The function to update the search.
 * @param {string} [props.idPrefix=''] - The ID prefix.
 * @param {Object} props.history - The history object.
 * @param {Array} props.sortOptions - The sort options.
 * @param {Object} props.messages - The messages object.
 * @returns {JSX.Element} The sort section component.
 */
function SortSection({
  searchSummary,
  getUpdatedSearch,
  idPrefix = '',
  history,
  sortOptions,
  messages
}) {
  return (
    <FacetFieldSet className="filter-group">
      <StyledLegend>
        <FormattedMessage {...messages.sortby} />
      </StyledLegend>
      <FacetWrapper className="filter-box multi">
        <BasicFacetBody
          data-testid={'filter-box-sort-by'}
          className="no-bullets"
          id={'facet-group-sort-by'}
        >
          {sortOptions.map((option) => {
            const SORT_BY_PREFIX = `${idPrefix}sort-by`;
            const sortById = `${SORT_BY_PREFIX}-${option.value}`;
            const updatedSearchQuery = getUpdatedSearch([
              {
                action: 'append',
                key: 'sort',
                value: option.value
              }
            ]);
            return (
              <li key={sortById}>
                <label
                  htmlFor={sortById}
                  className="pr-s"
                  id={sortById + '-label'}
                >
                  <input
                    id={sortById}
                    data-testid={sortById}
                    type="radio"
                    onClick={() =>
                      history.push(
                        `${updatedSearchQuery.pathname}?${updatedSearchQuery.search}`
                      )
                    }
                    defaultChecked={option.value == searchSummary.sort}
                    name={'mobile-sort-by'}
                    className="mr-xs"
                  />
                  <FormattedMessage {...option.descriptor} />
                </label>
              </li>
            );
          })}
        </BasicFacetBody>
      </FacetWrapper>
    </FacetFieldSet>
  );
}

export default SortSection;
